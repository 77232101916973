exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-trivial-js": () => import("./../../../src/pages/trivial.js" /* webpackChunkName: "component---src-pages-trivial-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

